import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NavigationEnd, Router } from '@angular/router';
import { PermissionsService } from './permissions.service';
import { KioskService } from './kiosk.service';
import { ApiService } from './api.service';
import { Feenics } from './model/model';
import { AuthenticationService } from './authentication/authentication.service';
import * as localModel from './model/local-model';
import { AppService } from './app.service';
import { IMqttServiceOptions, MqttConnectionState, MqttService } from 'ngx-mqtt';
import { Subscription } from 'rxjs';
import { BSON } from '../bson';
import { LicenseCheckService } from './license-check.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private permissionsService: PermissionsService,
    private kioskService: KioskService,
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    public appService: AppService,
    private mqttService: MqttService,
    private toastCtrl: ToastController,
    private licenseCheckService: LicenseCheckService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.initializeApp();
    if (localStorage.getItem('access_token') === null || localStorage.getItem('access_token') === undefined) {
      this.router.navigate(['authentication']);
    } else {
      this.kioskService.setTag();
      this.router.navigate(['home']);
      this.permissionsService.getPermissions();
      this.refreshCounter();
      this.subscribeToPermissions();
      const currentStoredLicense = localStorage.getItem('license');
      this.confirmVisitorManagementLicense(currentStoredLicense ? JSON.parse(currentStoredLicense) : null);
    }

    this.authenticationService.loggedIn.subscribe({
      next: isLoggedIn => {
        if (isLoggedIn) {
          console.log('authentication success: ', isLoggedIn);
          this.subscribeToPermissions();
          this.appService.getSysInfo().subscribe({
            next: sysInfo => {
              if (sysInfo.EventPublisherUrl) {
                this.startMqtt(sysInfo);
              }
            }
          });
        } else {
          this.appService.customLogoLoaded = false;
        }
      }
    });
    this.router.events.subscribe(
      routeEvent => {
        if (routeEvent instanceof NavigationEnd) {
          if (routeEvent.urlAfterRedirects.includes('badge')) {
            this.printing = true;
          } else {
            this.printing = false;
          }
        }
      }
    );
    this.appService.browserCheckAnnounced$.subscribe({
      next: (isBrowser) => this.runningInBrowser = isBrowser
    });
    this.appService.licensesAnnounced$.subscribe({
      next: licenseInfo => this.confirmVisitorManagementLicense(licenseInfo)
    });
  }

  mqttErrorSub: Subscription;
  mqttStatusSub: Subscription;
  mqttOnOfflineSub: Subscription;
  mqttConnectedSub: Subscription;
  browserCheckSub: Subscription;
  permissionsSub: Subscription;
  mqttVisitorManagementSettingsSub: Subscription;
  customLogoImageInfo: localModel.localImageInfoPost;
  printing = false;
  runningInBrowser = this.apiService.runningInBrowser;

  async presentToast(text) {
    const toast = await this.toastCtrl.create({
      message: text,
      duration: 3000,
      position: 'bottom'
    });
    toast.present();
  }

  confirmVisitorManagementLicense(license: Feenics.Keep.WebApi.Model.LicenseInfo | null) {
    if (license !== null) {
      if (this.licenseCheckService.checkFeatureLicense(['VisitorManagement'], license) === false) {
        this.presentToast('Instance is not licensed for Visitor Management');
        setTimeout(() => {
          this.apiService.logOut();
        }, 5000);
      }
    }
  }

  subscribeToPermissions() {
    this.permissionsSub = this.permissionsService.permissionsAnnounced$.subscribe({
      next: () => {
        if (this.permissionsService.returnPermission('Visit', 'Create') === false) {
          this.presentToast('Permissions insufficient for Visitor Management');
          setTimeout(() => {
            this.apiService.logOut();
          }, 2000);
        }
      },
      error: error => console.log('Get permissions sub error: ', error)
    });
  }

  initializeMqtt() {
    this.mqttVisitorManagementSettingsSub = this.mqttService.observe(`/${this.apiService.instance}/${this.kioskService.visitorManagementSettings.Key}`).subscribe({
      next: event => {
        const eventPayload = BSON.deserialize(event.payload) as Feenics.Keep.WebApi.Model.EventMessageData;
        const u8A: Uint8Array = new Uint8Array(this.apiService.convertDataURIToBinary(eventPayload.EventDataBsonBase64));
        const deserializedEvent: any = BSON.deserialize(u8A, true);

        switch (deserializedEvent.Action) {
          case 'PUT':
          case 'POST':
            const newVisitorManagementSettings: Feenics.Keep.WebApi.Model.VisitorManagementSettingsInfo = deserializedEvent.AfterData;
            newVisitorManagementSettings.$type = this.return_t(deserializedEvent.AfterData._t);
            this.kioskService.visitorManagementSettings = newVisitorManagementSettings;
            break;
          case 'DELETE':
            this.kioskService.visitorManagementSettings = null;
            this.createDefaultVMSettings();
            break;
          default:
            // ignore
            break;
        }
      } 
    });
  }

  return_t(_t: any): string {
    const type = (Array.isArray(_t)) ? _t[_t.length - 1] : _t;
    return `Feenics.Keep.WebApi.Model.${type}, Feenics.Keep.WebApi.Model`;
  }

  startMqtt(sysInfo: Feenics.Keep.WebApi.Model.SysInfo) {
    localStorage.setItem('mqttHost', sysInfo.EventPublisherUrl.replace('wss://', ''));
    const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
      hostname: sysInfo.EventPublisherUrl.replace('wss://', ''),
      port: 443,
      path: '/mqtt',
      username: this.apiService.access_token,
      protocol: 'wss',
      connectOnCreate: true,
      keepalive: 7
    };
    this.mqttService.connect(MQTT_SERVICE_OPTIONS);
    this.mqttErrorSub = this.mqttService.onError.subscribe(
      mqttError => console.error('MQTT error: ', mqttError)
    );
    this.mqttStatusSub = this.mqttService.state.subscribe(
      mqttStatus => console.log('MQTT status: ', MqttConnectionState[mqttStatus])
    );
    this.mqttConnectedSub = this.mqttService.onConnect.subscribe(
      mqttConnected => {
        console.log('MQTT connected: ', mqttConnected);
        this.getVisitorManagementSettings();
      }
    );
    this.mqttOnOfflineSub = this.mqttService.onOffline.subscribe(
      () => console.log('MQTT offline')
    );
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.apiService.runningInBrowser =  this.platform.is('desktop') || this.platform.is('mobileweb');
      this.appService.announceBrowserCheck(this.platform.is('desktop') || this.platform.is('mobileweb'));
      console.log('runningInBrowser in initializeApp: ', this.apiService.runningInBrowser);
    });
  }

  refreshCounter() {
    const now = new Date();
    const tokenProvided: Date = (localStorage.getItem('token_provided') !== null) ? new Date(localStorage.getItem('token_provided')) : now;
    const tokenExpiry: Date = new Date(tokenProvided.getTime() + (Number(localStorage.getItem('expires_in')) * 1000));
    const renewalTime: Date = new Date(tokenExpiry.getTime() - 300000);
    console.log('refreshCounter called refresh token: : ', localStorage.getItem('refresh_token'));
    if (localStorage.getItem('refresh_token') !== null) {
      setTimeout(() => {
        this.renewToken();
      }, renewalTime.getTime() - now.getTime());
    }
  }

  renewToken() {
    let loginDetails: any;
    this.authenticationService.postTokenRenew().subscribe(
      detailsRetrieved => loginDetails = detailsRetrieved,
      error => {
        console.log('renewToken error: ', error);
        if (error.status === 400 || error.status === 401) {
          localStorage.clear();
          location.reload();
        }
        console.log('Token refresh failed')
      },
      () => {
        console.log('Token refreshed successfully.');
        this.apiService.instance = loginDetails.instance;
        this.apiService.access_token = loginDetails.access_token;
        localStorage.setItem('instance', loginDetails.instance);
        localStorage.setItem('rootInstance', loginDetails.instance);
        localStorage.setItem('userName', loginDetails.userName);
        localStorage.setItem('refresh_token', loginDetails.refresh_token);
        localStorage.setItem('expires_in', loginDetails.expires_in);
        localStorage.setItem('token_provided', new Date().toISOString());
        localStorage.setItem('access_token', loginDetails.access_token);
        location.reload();
      }
    );
  }


  getVisitorManagementSettings() {
    console.log('getting visitor management settings');
    this.appService.customLogoLoaded = null;
    this.kioskService.getVisitorManagementSettings().subscribe(
      retrievedVMSettings => {
        if (retrievedVMSettings.length === 0) {
          console.log('creating default visitor management settings');
          this.createDefaultVMSettings();
        } else {
          this.kioskService.visitorManagementSettings = retrievedVMSettings[0];
          this.apiService.getImage(this.kioskService.visitorManagementSettings.Href, 'custom.vm.kiosk.logo').subscribe(
            imageInfo => {
              console.log('loading custom logo');
              this.customLogoImageInfo = imageInfo;
            },
            error => {
              console.log('using default logo');
              this.appService.customLogoLoaded = false;
            },
            () => {
              console.log('custom logo loaded');
              this.appService.customLogoLoaded = true;
            }
          );
          // this.kioskService.annouceVMSettings(this.appService.visitorManagementSettings);
        }
      },
      error => {
        if (this.apiService.handleErrorResponse('Get VMSettings', error, false)) {
          location.reload();
        }
      },
      () => {
        this.initializeMqtt();
      }
    );
  }

  createDefaultVMSettings() {
    const instanceName: string = JSON.parse(localStorage.getItem('InstanceInfo')).CommonName;
    const defaultVMSettings: Feenics.Keep.WebApi.Model.VisitorManagementSettingsInfo = {
      $type: 'Feenics.Keep.WebApi.Model.VisitorManagementSettingsInfo, Feenics.Keep.WebApi.Model',
      CommonName: `${instanceName} Visitor Management Settings`,
      VisitorCardNumberRangeStart: '0',
      VisitByEmailAddress: null,
      Key: null,
      DefaultIDScanRegion: 'United States',
      ScannerNames: [],
      LocalCountry: '',
      Href: null,
      ObjectLinks: [],
      Metadata: [],
      Monikers: [],
      Links: [],
      Tags: [],
      Notes: [],
      OwnerCommonName: null,
      OwnerHref: null,
      InFolderHref: null,
      InFolderKey: null,
    };
    this.kioskService.postVisitorManagementSettings(defaultVMSettings).subscribe(
      postedVMSettings => this.kioskService.visitorManagementSettings = postedVMSettings,
      error => {
        if (this.apiService.handleErrorResponse('POST VM Settings', error, true)) {
          location.reload();
        }
      },
      () => console.log('saved default visitor management settings: ', this.kioskService.visitorManagementSettings)
    );
  }

  isAccessExpert(): boolean {
    return this.document.location.hostname.toLowerCase().includes('accessxpert');
  }
}
