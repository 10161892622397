import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { Feenics } from '../model/model';
import * as localModel from '../model/local-model';
import * as xml2js from 'xml2js';
import { Subject } from 'rxjs';

export interface ResetPasswordResponse {
  errorResponse: localModel.AuthenticationResponse;
  loginDetails: any;
  updatedCredentials: localModel.credentials;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private httpClient: HttpClient,
    private apiService: ApiService
    ) { }
  loggedIn: Subject<boolean> = new Subject<boolean>();

  announceLoggedIn(isLoggedIn: boolean) {
    this.loggedIn.next(isLoggedIn);
  }

  checkLoginStatus(url: string): boolean {
    if (localStorage.getItem('access_token') !== null) {
      return true;
    } else {
      console.log('CanActivate reroute to login');
      this.apiService.originURI = url;
      // this.router.navigate(['login']);
      console.log('navigate: \'login\'');
      return false;
    }
  }

  postToken(instance: string, username: string, password: string) {
    const body =
      'grant_type=password&' +
      'client_id=consoleApp&' +
      'client_secret=consoleSecret&' +
      'username=' + encodeURIComponent(username) + '&' +
      'instance=' + encodeURIComponent(instance) + '&' +
      'password=' + encodeURIComponent(password);

    return this.httpClient.post<any>(this.apiService.APIURL + '/token', body, this.apiService.returnHttpOptionsForToken());
  }

  postTokenRenew() {
    const renewToken = localStorage.getItem('refresh_token');
    const body =
      `grant_type=refresh_token&client_id=consoleApp&client_secret=consoleSecret&refresh_token=${renewToken}`;

    return this.httpClient.post<any>(`${this.apiService.APIURL}/token`, body, this.apiService.returnHttpOptionsForToken());
  }

  postTokenReset(instance: string, username: string, password: string, newPassword: string) {
    const body =
      'grant_type=password&' +
      'client_id=consoleApp&' +
      'client_secret=consoleSecret&' +
      'username=' + encodeURIComponent(username) + '&' +
      'password=' + encodeURIComponent(password) + '&' +
      'newpassword=' + encodeURIComponent(newPassword) + '&' +
      'instance=' + encodeURIComponent(instance) + '&' +
      'sendonetimepassword=' + encodeURIComponent('False');

    return this.httpClient.post<any>(this.apiService.APIURL + '/token', body, this.apiService.returnHttpOptionsForToken());
  }

  postTokenOneTime(instance: string, username: string, password: string) {
    const body =
      'grant_type=password&' +
      'client_id=consoleApp&' +
      'client_secret=consoleSecret&' +
      'username=' + encodeURIComponent(username) + '&' +
      'password=' + encodeURIComponent(password) + '&' +
      'instance=' + encodeURIComponent(instance) + '&' +
      'sendonetimepassword=' + encodeURIComponent('True');

    return this.httpClient.post<any>(this.apiService.APIURL + '/token', body, this.apiService.returnHttpOptionsForToken());
  }

  postTokenOneTimeValue(instance: string, username: string, password: string, oneTimePassword: string) {
    const body =
      'grant_type=password&' +
      'client_id=consoleApp&' +
      'client_secret=consoleSecret&' +
      'username=' + encodeURIComponent(username) + '&' +
      'password=' + encodeURIComponent(password) + '&' +
      'instance=' + encodeURIComponent(instance) + '&' +
      'onetimepassword=' + encodeURIComponent(oneTimePassword) + '&' +
      'sendonetimepassword=' + encodeURIComponent('False');

    return this.httpClient.post<any>(this.apiService.APIURL + '/token', body, this.apiService.returnHttpOptionsForToken());
  }

  postRefreshToken(refreshToken: string) {
    const body =
      'grant_type=password&' +
      'client_id=consoleApp&' +
      'client_secret=consoleSecret&' +
      'refresh_token=' + refreshToken;

    return this.httpClient.post<any>(this.apiService.APIURL + '/token', body, this.apiService.returnHttpOptionsForToken());
  }

  getAPIDetails() {
    const URI = this.apiService.APIURL;

    return this.httpClient.get<Feenics.Keep.WebApi.Model.InstanceInfo>(URI + '/api', this.apiService.returnHttpOptions());
  }

  getUserDetails() {
    const callPath = this.apiService.APIURL + '/api/currentuser';

    return this.httpClient.get<Feenics.Keep.WebApi.Model.UserInfo>(callPath, this.apiService.returnHttpOptions())
      .pipe(map(res => {
        const userInfo = res;
        userInfo.AcceptedEULA = ('AcceptedEULA' in userInfo) ? userInfo.AcceptedEULA : true;
        return userInfo;
      })
      );
  }

  getInstanceSettings(instanceToCall: string) {
    const callPath: string = this.apiService.APIURL + '/api/f/' + instanceToCall + '/settings'

    return this.httpClient.get<Feenics.Keep.WebApi.Model.InstanceSettingsInfo>(callPath, this.apiService.returnHttpOptions());
  }

  putSettings(settings: Feenics.Keep.WebApi.Model.InstanceSettingsInfo, instanceToCall: string) {
    const callPath: string = this.apiService.APIURL + '/api/f/' + instanceToCall + '/settings';

    return this.httpClient.put(callPath, JSON.stringify(settings), this.apiService.returnHttpOptions());
  }

  getLicenses() {
    const callPath: string = this.apiService.APIURL + '/api/f/' + this.apiService.instance + '/licenses';

    return this.httpClient.get<Feenics.Keep.WebApi.Model.LicenseInfo[]>(callPath, this.apiService.returnHttpOptions());
  }

  getCurrentLicense(licInfos: Feenics.Keep.WebApi.Model.LicenseInfo[]): Feenics.Keep.WebApi.Model.LicenseInfo {
    const mapped = licInfos.map((lic, i) => {
      return { index: i, value: this.parseLicenseId(lic.LicenseIdentifier) };
    });
    mapped.sort((a, b) => {
      return +(a.value > b.value) || +(a.value === b.value) - 1;
    });
    const sortedLicInfos = mapped.map((el) => licInfos[el.index]);
    localStorage.setItem('license', JSON.stringify(licInfos[licInfos.length - 1]));
    return licInfos[licInfos.length - 1];
  }

  parseLicenseId(id: string): number {
    const timestamp = id.toString().substring(0, 8);
    const date = new Date(parseInt(timestamp, 16) + 1000).getTime();
    return date;
  }

  checkLicense(license: Feenics.Keep.WebApi.Model.LicenseInfo, feature: string): boolean {
    if (!license.Certificate) {
      return false;
    }
    let mobileLicensed = false;
    let certObject: any;
    xml2js.parseString(license.Certificate, (err, result) => {
      if (err) {
        console.log('xml parse error: ', err);
      } else {
        certObject = result;
      }
    });
    const featureList: any[] = certObject.License.ProductFeatures[0].Feature;
    for (const item of featureList) {
      if (JSON.parse(item._).CurrentValue === 1) {
        mobileLicensed = true;
        break;
      }
    }
    return mobileLicensed;
  }

  getCurrentInstance() {
    const callPath: string = this.apiService.APIURL + '/api/';

    return this.httpClient.get<Feenics.Keep.WebApi.Model.InstanceInfo>(callPath, this.apiService.returnHttpOptions());
  }

  acceptEULA() {
    const callPath: string = this.apiService.APIURL + '/api/currentuser/accepteula';

    return this.httpClient.put(callPath, null, this.apiService.returnHttpOptions());
  }
}

