import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Feenics } from './model/model';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private apiService: ApiService,
    private httpClient: HttpClient
  ) { }

  private logOutAnnouncedSource = new Subject<boolean>();
  private browserCheckAnnouncedSource = new Subject<boolean>();
  private licensesAnnouncedSource = new Subject<Feenics.Keep.WebApi.Model.LicenseInfo>();
  customLogoLoaded = false;
  logOutAnnounced$ = this.logOutAnnouncedSource.asObservable();
  browserCheckAnnounced$ = this.browserCheckAnnouncedSource.asObservable();
  licensesAnnounced$ = this.licensesAnnouncedSource.asObservable();

  announceLicenses(licenses: Feenics.Keep.WebApi.Model.LicenseInfo) {
    this.licensesAnnouncedSource.next(licenses);
  }

  announceLogOut() {
    this.logOutAnnouncedSource.next(true);
  }

  announceBrowserCheck(isBrowser: boolean) {
    this.browserCheckAnnouncedSource.next(isBrowser);
  }

  postDeviceEndpoint(notificationRegistration: any) {
    const callPath: string = this.apiService.APIURL + localStorage.getItem('userObjectInFolderHref') + '/mobileplatformappendpoint';

    return this.httpClient.post<any>(callPath, JSON.stringify(notificationRegistration), this.apiService.returnHttpOptions());
  }

  deleteDeviceEndpoint(ArnValue: string, userObjectInFolderHref) {
    const callPath: string =
      this.apiService.APIURL + userObjectInFolderHref + '/mobileplatformappendpoint?arn=' + encodeURIComponent(ArnValue);

    return this.httpClient.delete(callPath, this.apiService.returnHttpOptions());
  }

  parseCommonName(commonName: string): string {
    const splitName = commonName.split(', ');
    return (splitName.length > 0) ? `${splitName[1]} ${splitName[0]}` : splitName[0];
  }

  getSysInfo() {
    const callPath = `${this.apiService.APIURL}/api/sysinfo`;
    return this.httpClient.get<Feenics.Keep.WebApi.Model.SysInfo>(callPath, this.apiService.returnHttpOptions());
  }
}

