import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PermissionsService } from './permissions.service';
import { HttpClientModule } from '@angular/common/http';
import { LoadingService } from './loading.service';
import { ApiService } from './api.service';
import { AppService } from './app.service';
import { KioskService } from './kiosk.service';
import { Printer } from '@ionic-native/printer/ngx';
import { MqttModule, IMqttServiceOptions } from 'ngx-mqtt';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LicenseCheckService } from './license-check.service';

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: localStorage.getItem('mqttHost'),
  port: 443,
  path: '/mqtt',
  username: localStorage.getItem('access_token'),
  protocol: 'wss',
  connectOnCreate: false
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    LoadingService,
    ApiService,
    AppService,
    PermissionsService,
    KioskService,
    Printer,
    LicenseCheckService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
