import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  isLoading = false;
  constructor(
    public loadingController: LoadingController
  ) { }

  async loadingPresent(loadingText: string) {
    this.isLoading = true;
    setTimeout(() => this.loadingDismiss(), 4000);
    return await this.loadingController.create({
      message: (loadingText) ? loadingText : 'Loading...',
      spinner: 'circles',
      showBackdrop: false
    }).then(a => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort loading'));
        }
      });
    });
  }

  async loadingDismiss() {
    this.isLoading = false;
    return await this.loadingController.getTop().then(a => {
      if (a) {
        a.dismiss().then(() => console.log('loading dismissed'));
      }
    });
  }
}

