import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Subject } from 'rxjs';
import { Feenics } from './model/model';

export enum Systems {
  'AccessLevel',
  'Address',
  'AlarmAction',
  'AlarmDefinition',
  'App',
  'BadgeTypeBarcodeField',
  'BadgeTypeField',
  'BadgeTypeIdPhotoField',
  'BadgeTypeImageField',
  'BadgeType',
  'BadgeTypeTextField',
  'Base',
  'CardAssignment',
  'CardFormat',
  'ControllerCardFormat',
  'Controller',
  'Downstream',
  'ElevatorAccessLevel',
  'EmailAddress',
  'EventType',
  'EventMessage',
  'FloorplanHardwareItem',
  'Floorplan',
  'FloorplanItem',
  'FloorplanLabelItem',
  'Folder',
  'Generic',
  'Group',
  'License',
  'HgProcedure',
  'HgSystemProcedure',
  'HgTrigger',
  'Holiday',
  'Image',
  'Instance',
  'InstanceSettings',
  'LocalArea',
  'MailingAddress',
  'MercuryController',
  'MercuryDownstream',
  'MercuryInput',
  'MercuryOutput',
  'MercuryPeripheral',
  'MercuryReader',
  'LeReader',
  'MessageTemplate',
  'NotificationAction',
  'OsdpReader',
  'Peripheral',
  'Person',
  'Phone',
  'Schedule',
  'User',
  'Visit',
  'WebHook',
  'Note',
  'BoschPanel',
  'BoschPanelPeripheral',
  'BoschArea',
  'BoschPoint',
  'BoschOutput',
  'Camera',
  'KeepObject',
  'VisitorManagementSettings'
}

export enum Relations {
  'User',
  'Person',
  'InInstance',
  'Instance',
  'InstanceScope',
  'AccessLevel',
  'Downstream',
  'Controller',
  'Rex',
  'Contact',
  'Strike',
  'DefaultReaderMode',
  'InArea',
  'BadgeType',
  'LockDownProcedure',
  'ResetDoorsProcedure',
  'Schedule',
  'PublishingApp',
  'PublishingEventType',
  'PublishingInstance',
  'ParentFolder',
  'PublishingUser'
}


@Injectable({
  providedIn: 'root'
})

export class PermissionsService {

  constructor(
    private httpClient: HttpClient,
    private apiService: ApiService
  ) { }

  Actions: string[] = ['Create', 'Read', 'Update', 'Delete', 'Publish'];
  limitedPermissions = false;
  apiPermissionsUnsupported = false;

  private permissionsAnnouncedSource = new Subject();
  permissionsAnnounced$ = this.permissionsAnnouncedSource.asObservable();

  addRelationsToActions(relations: Relations[]): string[] {
    const actions: string[] = [];
    relations.forEach(rel => {
      actions.push(`AddLink:${Relations[rel]}`);
      actions.push(`RemoveLink:${Relations[rel]}`);
    });
    const allActions = actions.concat(this.Actions);
    return allActions;
  }

  returnRightsRequests(appKey: string, actions: string[], systems: Systems[]) {
    const rightsRequests: Feenics.Keep.WebApi.Model.VerifyRightsRequest[] = [];
    actions.forEach(ac => {
      systems.forEach(sy => {
        const newRightRequest: Feenics.Keep.WebApi.Model.VerifyRightsRequest = new Feenics.Keep.WebApi.Model.VerifyRightsRequest();
        newRightRequest.Action = ac;
        newRightRequest.AppKey = appKey;
        newRightRequest.System = Systems[sy];
        rightsRequests.push(newRightRequest);
      });
    });
    return rightsRequests;
  }

  getRightsResponses(rightsRequests: Feenics.Keep.WebApi.Model.VerifyRightsRequest[]) {
    const callPath = `${this.apiService.APIURL}/api/f/${this.apiService.instance}/operationrights/verify`;

    return this.httpClient.post<Feenics.Keep.WebApi.Model.VerifyRightsResponse[]>(
      callPath, JSON.stringify(rightsRequests), this.apiService.returnHttpOptions());
  }

  getPermissions() {
    const rightsRequests = this.returnRightsRequests(
      'KeepApi',
      ['Read', 'Create', 'Update'],
      [
        // Systems.Instance,
        // Systems.Person,
        // Systems.Address,
        // Systems.EmailAddress,
        // Systems.Phone,
        // Systems.MailingAddress,
        Systems.EventType,
        Systems.EventMessage,
        // Systems.CardFormat,
        // Systems.Schedule,
        // Systems.Holiday,
        // Systems.AccessLevel,
        // Systems.ElevatorAccessLevel,
        // Systems.Generic,
        Systems.Visit,
        // Systems.User,
        // Systems.KeepObject,
        // Systems.BoschPanel,
        // Systems.MercuryController,
        // Systems.MercuryReader,
        Systems.VisitorManagementSettings
      ]
    );
    if (this.apiService.instance === null) { return; }
    this.getRightsResponses(rightsRequests).subscribe(
      retrievedRightsResponses => this.apiService.rightsResponses = retrievedRightsResponses,
      error => {
        if (error.status === 400 || error.status === 404) {
          console.log('Permissions not supported by this API version.');
          this.apiPermissionsUnsupported = true;
        } else {
          if (this.apiService.handleErrorResponse('Get Rights Responses', error, true)) {
            location.assign('');
          }
        }
      },
      () => {
        this.permissionsAnnouncedSource.next(true);
      }
    );
  }

  returnPermission(system: string, action: string) {
    if (this.apiPermissionsUnsupported === true) {
      return true;
    } else {
      return this.apiService.rightsResponses.some(
        resp => (resp.Request.System === system &&
          resp.Request.Action === action &&
          (resp.Granted === true || resp.Granted === null)));
    }
  }

}

