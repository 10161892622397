import { Injectable } from '@angular/core';
import { Feenics } from './model/model';
import * as xml2js from 'xml2js';

@Injectable()

export class LicenseCheckService {

  constructor() {}

  varInstance = false;
  enterpriseInstance = false;
  standardInstance = false;

  checkFeatureLicense(features: string[], license: Feenics.Keep.WebApi.Model.LicenseInfo): boolean {
    if (license === null || !license.Certificate) {
      return false;
    }
    let certObject: any;
    xml2js.parseString(license.Certificate, (err: any, result: any) => {
      if (err) {
        console.log('XML parse error: ', err);
      } else {
        certObject = result;
      }
    });
    const featureList: any[] = certObject.License.ProductFeatures[0].Feature;
    const licensed = features.some(feature => this.isLicensed(featureList, feature));
    return licensed;
  }

  checkVarInstance(certObject: any) {
    certObject.License.LicenseAttributes[0].Attribute.forEach((att: any) => {
      if (att.$.name === 'InstanceType') {
        this.varInstance = (att._ === 'VarInstance');
      }
    });
    certObject.License.LicenseAttributes[0].Attribute.forEach((att: any) => {
      if (att.$.name === 'InstanceType') {
        this.enterpriseInstance = (att._ === 'EnterpriseInstance');
      }
    });
    certObject.License.LicenseAttributes[0].Attribute.forEach((att: any) => {
      if (att.$.name === 'InstanceType') {
        this.standardInstance = (att._ === 'StandardInstance');
      }
    });
  }

  isLicensed(featurelist: any, feature: string): boolean {
    for (let lC = 0; lC < featurelist.length; lC++) {
      if (featurelist[lC].$.name === feature) {
        if (JSON.parse(featurelist[lC]._).CurrentValue === 1) {
          return true;
        }
      }
    }
    return false;
  }

}
