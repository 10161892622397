
export namespace Feenics.Keep.WebApi.Model {
  export const enum AddressingModes {
    EpDHCP = 0,
    PublicDHCP = 1,
    StaticIP = 2
  }
  export enum AttendeeStatus {
    Unconfirmed = 0,
    Confirmed = 1,
    CheckedIn = 2,
    CheckedOut = 3
  }
  export const enum AuthenticationMethods {
    None = 0,
    Basic = 1
  }
  export const enum BadgeSides {
    Front = 0,
    Back = 1
  }
  export const enum BadgeTypeOrientations {
    Landscape = 0,
    Portrait = 1
  }
  export const enum LocalAreaReaderDirections {
    In = 0,
    Out = 1
  }
  export const enum MercuryAntiPassbackTypes {
    None = 0,
    Soft = 1,
    Hard = 2,
    Timed = 3
  }
  export const enum MercuryElevatorType {
    None = 0,
    NoFloorSelect = 1,
    ElevatorFloorSelect = 2,
    OTISDestiniationDispatching = 3
  }
  export const enum MercuryKeypadTypes {
    None = 0,
    Hid4Bit = 1,
    Hid8Bit = 2,
    Mr20 = 3,
    Motorola8Bit = 4,
    Mr20NoTamper = 5,
    FourBit60Second = 6,
    EightBit60Second = 7,
    FourBit10Second = 8,
    EightBit10Second = 9
  }
  export enum MercuryReaderAccessMethods {
    Card = 0,
    PinOnly = 1,
    CardAndPin = 2,
    CardOrPin = 3,
    FacilityCodeOnly = 4,
    Unlock = 5,
    LockDown = 6,
    CardAndPinNoGrantOnDuress = 7
  }
  export const enum MercuryReaderTypes {
    Wiegand = 0,
    ClockAndData = 1,
    WiegandWithMagStripe = 2,
    ClockAndDataWithMagStripe = 3,
    F2F = 4,
    SupervisedF2F = 5,
    SupervisedF2FInputsAtReader = 6
  }
  export const enum OsdbBaudRates {
    Baud9600 = 1,
    Baud19200 = 2,
    Baud38400 = 3,
    AutoNegotiate = 7
  }
  export const enum PasswordComplexity {
    None = 1,
    RequireUpperCase = 2,
    RequireLowerCase = 4,
    RequireNumber = 8,
    RequireSpecial = 16
  }
  export const enum Privilege {
    None = 1,
    List = 2,
    Read = 4,
    Create = 8,
    Update = 16,
    Delete = 32,
    TakeOwnership = 64,
    Publish = 128
  }
  export const enum SchlageKeypadTypes {
    None = 0,
    AsciiOddParityMsbFirst = 1,
    FourBitsPerKey = 2,
    EightBitsPerKey = 3
  }
  export const enum SchlageLockFunctions {
    None = 0,
    Classroom = 1,
    Office = 2,
    Privacy = 3,
    Apartment = 4
  }
  export const enum SecurityLevels {
    High = 0,
    Medium = 1,
    Low = 2,
    None = 3
  }
  export const enum TfaRequestActions {
    Generate = 0,
    Delete = 1,
    GetQrCode = 2,
    GetString = 3,
    VerifyCode = 4
  }
  export enum VisitStatus {
    Unconfirmed = 0,
    Confirmed = 1,
    Cancelled = 2
  }
  export interface VisitorManagementSettingsInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    VisitorCardNumberRangeStart: string;
    LocalCountry: string;
    DefaultIDScanRegion: string;
    ScannerNames: string[];
    VisitByEmailAddress: string;
  }
  export interface AccessLevelElevatorEntryItem {
    ElevatorAccessLevelCommonName: string;
    ElevatorAccessLevelId: string;
    ReaderCommonName: string;
    ReaderId: string;
  }
  export interface AccessLevelEntryItem {
    ReaderCommonName: string;
    ReaderId: string;
    ScheduleCommonName: string;
    ScheduleId: string;
  }
  export interface AccessLevelInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    AccessLevelEntries: Feenics.Keep.WebApi.Model.AccessLevelEntryItem[];
    ElevatorAccessLevelEntries: Feenics.Keep.WebApi.Model.AccessLevelElevatorEntryItem[];
    StartsOn: string;
    EscortType: EscortTypes;
    EndsOn: string;
    Index: number;
    IsGlobal: boolean;
  }
  export enum EscortTypes {
    None,
    IsEscort,
    RequiresEscort
  }
  export interface ActivePortItem {
    BaudRate: number;
    DriverNumber: number;
  }
  export interface Ad400ReaderInfo extends Feenics.Keep.WebApi.Model.MercuryReaderInfo {
    LockFunction: Feenics.Keep.WebApi.Model.SchlageLockFunctions;
    LockKeypadType: Feenics.Keep.WebApi.Model.SchlageKeypadTypes;
    LockStatus: Feenics.Keep.WebApi.Model.SchlageStatusItem;
    SerialNumber: string;
    VersionNumber: string;
  }

  export interface Ad300ReaderInfo extends Feenics.Keep.WebApi.Model.MercuryReaderInfo {
    LockFunction: Feenics.Keep.WebApi.Model.SchlageLockFunctions;
    LockKeypadType: Feenics.Keep.WebApi.Model.SchlageKeypadTypes;
    LockStatus: Feenics.Keep.WebApi.Model.SchlageStatusItem;
    SerialNumber: string;
    VersionNumber: string;
  }

  export interface AddressInfo extends Feenics.Keep.WebApi.Model.Item {
    IsPrivate: boolean;
    Key: string;
    Type: string;
  }
  export interface AlarmAcknowledgementItem {
    CloseEvent: boolean;
    Comment: string;
    Key: string;
    CreatedOn: Date;
    HasImage: boolean;
  }
  export interface AlarmActionInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    GracePeriod: TimeSpan;
    IsEnabled: boolean;
    Logged: boolean;
  }
  export interface AlarmDefinitionInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    FilterQuery: string;
    MessageTemplateLong: string;
    MessageTemplateShort: string;
    Priority: number;
    RequiresAcknowledgement: boolean;
  }
  export interface Anchor {
    Href: string;
    Text: string;
  }
  export interface AperioHubInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface Ac_1Info extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface Ac_1PInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface FnNl4Info extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface RIM2eInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface Ad300_RsdInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo { }
  export interface AperioReaderInfo extends Feenics.Keep.WebApi.Model.MercuryReaderInfo {
  }
  export interface AppInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    ApiKey: string;
  }
  export interface AttendeeItem extends Feenics.Keep.WebApi.Model.Item {
    Person: Feenics.Keep.WebApi.Model.PersonInfo;
    Status: Feenics.Keep.WebApi.Model.AttendeeStatus;
  }
  export interface BadgeTypeBarcodeFieldInfo extends Feenics.Keep.WebApi.Model.BadgeTypeFieldInfo {
    BarcodeSymbology: string;
    Value: string;
  }
  export interface BadgeTypeFieldInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    BadgeSide: Feenics.Keep.WebApi.Model.BadgeSides;
    BorderBrush: string;
    BorderThickness: number;
    Height: number;
    Opacity: number;
    Rotation: number;
    Width: number;
    X: number;
    Y: number;
    ZIndex: number;
  }
  export interface BadgeTypeIdPhotoFieldInfo extends Feenics.Keep.WebApi.Model.BadgeTypeFieldInfo {
    PhotoCategory: string;
  }
  export interface BadgeTypeImageFieldInfo extends Feenics.Keep.WebApi.Model.BadgeTypeFieldInfo {
  }
  export interface BadgeTypeInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    AbsoluteExpiryDate: Date;
    BackgroundColor: string;
    BackBackgroundColor: string;
    BackOrientation: Feenics.Keep.WebApi.Model.BadgeTypeOrientations;
    FrontOrientation: Feenics.Keep.WebApi.Model.BadgeTypeOrientations;
    SlidingExpirySpan: string;
    FrontBorderThickness: number;
    FrontBorderBrush: string;
    BackBorderThickness: number;
    BackBorderBrush: string;
    Width: number;
    Height: number;
  }
  export interface BadgeTypeTextFieldInfo extends Feenics.Keep.WebApi.Model.BadgeTypeFieldInfo {
    AutoSize: boolean;
    Color: string;
    FontFamily: string;
    FontSize: number;
    FontStyle: string;
    FontWeight: string;
    Text: string;
    TextAlignment: string;
    UpperCase: boolean;
  }
  export interface BaseInfo extends Feenics.Keep.WebApi.Model.Item {
    CommonName: string;
    InFolderHref: string;
    InFolderKey: string;
    Key: string;
    Links: Feenics.Keep.WebApi.Model.Link[];
    Metadata: Feenics.Keep.WebApi.Model.MetadataItem[];
    Monikers: Feenics.Keep.WebApi.Model.MonikerItem[];
    ObjectLinks: Feenics.Keep.WebApi.Model.ObjectLinkItem[];
    OwnerCommonName: string;
    OwnerHref: string;
    Notes: Feenics.Keep.WebApi.Model.NoteInfo[];
    Tags: string[];
  }
  export interface CardAccessItem {
    AccessLevels: string[];
    CardAssignment: Feenics.Keep.WebApi.Model.CardAssignmentInfo;
  }
  export interface CardAssignmentInfo extends Feenics.Keep.WebApi.Model.Item {
    ActiveOn: Date;
    AntiPassbackExempt: boolean;
    DisplayCardNumber: string;
    EncodedCardNumber: string;
    ExpiresOn: Date;
    ExtendedAccess: boolean;
    IsDisabled: boolean;
    Key: string;
    ManagerLevel: number;
    PinCode: string;
    PinExempt: boolean;
    CurrentUseCount: number;
    OriginalUseCount: number;
    OrigoCredentialId: number;
    Note: string;
  }
  export interface CardFormatInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    CardNumberLength: number;
    CardNumberStartingAt: number;
    EvenParityBitsLength: number;
    EvenParityBitsStartingAt: number;
    FacilityCode: number;
    FacilityCodeLength: number;
    FacilityCodeStartingAt: number;
    IssueCodeLength: number;
    IssueCodeStartingAt: number;
    MagneticFormat: boolean;
    NumberOfBits: number;
    OddParityBitsLength: number;
    OddParityBitsStartingAt: number;
    MinCardNumberDigits: number;
    Offset: number;
    ReverseBitStream: boolean;
    CardFieldAsLargeEncodedNumber: boolean;
    ReverseBytes: boolean;
    EnableSpecial37BitParityCheck: boolean;

  }
  export interface CommunicationPortDefinitionItem {
    BoardPort: string;
    CommonName: string;
    DriverNumber: number;
    IsInternal: boolean;
    IsNetwork: boolean;
    NumberOfDownstreamDevices: number;
    PhysicalNumber: number;
  }
  export interface ControllerCardFormatInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    CardFormatInfo: Feenics.Keep.WebApi.Model.CardFormatInfo;
  }
  export interface ControllerInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    LastUpdatedOn: Date;
    MacAddress: string;
    TimeZone: string;
    Version: string;
    IsDisabled: boolean;
    LastPing?: string;
  }
  export interface ControllerStatusItem {
    IsBatteryLow: boolean;
    IsFaulted: boolean;
    IsOnline: boolean;
    IsTampered: boolean;
  }
  export interface DownstreamInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
  }
  export interface DownstreamStatusItem {
    CommunicationMessage: string;
    IsOnline: boolean;
    IsPowered: boolean;
    IsTampered: boolean;
  }
  export interface ElevatorAccessLevelInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    Index: number;
  }
  export interface ElevatorFloorsMeta {
    Floors: number[];
  }
  export interface ElevatorInputMeta {
    InputNumber: number;
  }
  export interface ElevatorOutputMeta {
    RelayNumber: number;
    RelayTimeMax: number;
    RelayTimeMin: number;
  }
  export interface EmailAddressInfo extends Feenics.Keep.WebApi.Model.AddressInfo {
    MailTo: string;
  }
  export interface Ep1501Info extends Feenics.Keep.WebApi.Model.MercuryControllerInfo {
  }
  export interface Ep1501OnBoardInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface Ep1502Info extends Feenics.Keep.WebApi.Model.MercuryControllerInfo {
  }
  export interface Ep1502OnBoardInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface Ep2500Info extends Feenics.Keep.WebApi.Model.MercuryControllerInfo {
  }
  export interface Ep4502Info extends Feenics.Keep.WebApi.Model.MercuryControllerInfo {
  }
  export interface Ep4502OnBoardInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface EventMessageData {
    AlarmAcknowledgements: Feenics.Keep.WebApi.Model.AlarmAcknowledgementItem[];
    AppKey: string;
    ClientDeviceId: string;
    EventDataBsonBase64: string;
    EventTypeKey: string;
    Href: string;
    InFolderKey: string;
    Key: string;
    Links: Feenics.Keep.WebApi.Model.Link[];
    MessageLong: string;
    MessageShort: string;
    ObjectLinks: Feenics.Keep.WebApi.Model.ObjectLinkItem[];
    OccurredOn: Date;
    Priority: number;
    PublishedOn: Date;
    RequiresAcknowledgement: boolean;
  }
  export interface EventMessagePosting {
    AppKey: string;
    EventDataBsonBase64: string;
    EventTypeMoniker: Feenics.Keep.WebApi.Model.MonikerItem;
    OccurredOn: Date;
    RelatedObjects: Feenics.Keep.WebApi.Model.ObjectLinkItem[];
  }
  export interface EventTypeInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    MessageTemplateLong: string;
    MessageTemplateShort: string;
    NonLogging: boolean;
    Priority: number;
    RequiresAcknowledgement: boolean;
  }
  export interface FloorplanHardwareItemInfo extends Feenics.Keep.WebApi.Model.FloorplanItemInfo {
    HardwareType: string;
  }
  export interface FloorplanInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
  }
  export interface FloorplanItemInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    X: number;
    Y: number;
  }
  export interface FloorplanLabelItemInfo extends Feenics.Keep.WebApi.Model.FloorplanItemInfo {
  }
  export interface FnM5_2KInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface FnM510InInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface FnM516DoInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface FnM516DorInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface FnM52RpInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface FnM52SrpInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface FnM58RpInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface FolderInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
  }
  export interface GenericInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    TypeName: string;
    Values: string;
  }
  export enum Editor {
    NoEditor,
    Person,
    Reader
  }
  export interface GroupInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    Description: string;
  }
  export interface HgEventTriggerItem extends Item {
    CommonName: string;
    EventArg: number;
    EventCode: number;
    EventSource: number;
    EventTransactions: number[];
    Key: string;
    SourceType: string;
  }
  export interface LicenseInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    LicenseIdentifier: string;
    Certificate: string;
  }
  export interface HgProcedureInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    Actions: string;
    ControllerIndex: number;
  }
  export interface HgSystemProcedureInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
  }
  export interface HgTriggerInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    ControllerIndex: number;
    EventTrigger: Feenics.Keep.WebApi.Model.HgEventTriggerItem;
    ManagerIndex: number;
    MangerLevel: number;
  }
  export interface HolidayDurationItem {
    CommonName: string;
    EndingOn: string;
    StartingOn: string;
  }
  export interface HolidayInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    HolidayDurations: Feenics.Keep.WebApi.Model.HolidayDurationItem[];
  }
  export interface HolidayDayInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    DayOfHoliday: Date;
    ForDays: number;
    HolidayExceptionType: HolidayExceptionTypes;
    IsSpecialDay: boolean;
    IsMaster: boolean;
  }
  export enum HolidayExceptionTypes {
    'Type1' = 1,
    'Type2' = 2,
    'Type3' = 4,
    'Type4' = 8,
    'Type5' = 16,
    'Type6' = 32,
    'Type7' = 64,
    'Type8' = 128
  }
  export interface HolidayTypesConfiguration {
    TypeNames: string[];
  }
  export interface ImageCapturedEventData {
    Device: string;
    ImageData: number[];
  }
  export interface ImageInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    Category: string;
    Image: number[];
  }
  export interface InputStatusItem extends Feenics.Keep.WebApi.Model.PeripheralStatusItem {
    FaultMessage: string;
    IsActivated: boolean;
    IsFaulted: boolean;
  }
  export interface InstanceInfo extends Feenics.Keep.WebApi.Model.FolderInfo {
    FullyQualifiedDomainName: string;
    Holiday2OptIn: boolean;
    PasswordPolicy: PasswordPolicy;
  }
  export interface InstanceSettingsInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    AdminEmailAddress: string;
    CharacterMask: number;
    DisconnectTimeout: number;
    DuressMode: number;
    ElevatorPushButtonTime: number;
    ElevatorRelayTime: number;
    IssueCodeSize: number;
    MaxAccessLevels: number;
    MaxAccessLevelsPerCardHolder: number;
    MaxCardHolders: number;
    MaxElevatorAccessLevels: number;
    TransactionNumber: number;
    UseCountIsEnabled: boolean;
    MaxElevatorFloors: number;
    PinLength: number;
    SecurityLevel: Feenics.Keep.WebApi.Model.SecurityLevels;
    MasterHolidayOptIn: boolean;
  }
  export interface Item {
    Href: string;
    $type: string;
  }
  export interface Link {
    Anchor: Feenics.Keep.WebApi.Model.Anchor;
    Relation: string;
  }
  export interface LocalAreaInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    Index: number;
    IsAirLock: boolean;
    IsEnabled: boolean;
    IsMultipleOccupancy: boolean;
    LogCountDown: number;
    LogCountUp: number;
    MaximumOccupancy: number;
    Occupancy: number;
  }
  export interface M5_ICInfo extends Feenics.Keep.WebApi.Model.MercuryControllerInfo {
  }
  export interface MacAddressQueryResult {
    CanonicalFormat: string;
    IsAvailable: boolean;
    IsValidFormat: boolean;
  }
  export interface MailingAddressInfo extends Feenics.Keep.WebApi.Model.AddressInfo {
    City: string;
    Country: string;
    PostalCode: string;
    Province: string;
    Street: string;
  }
  export interface MercuryControllerInfo extends Feenics.Keep.WebApi.Model.ControllerInfo {
    ActivePorts: Feenics.Keep.WebApi.Model.ActivePortItem[];
    Status: Feenics.Keep.WebApi.Model.ControllerStatusItem;
  }
  export interface MercuryDownstreamInfo extends Feenics.Keep.WebApi.Model.DownstreamInfo {
    DriverNumber: number;
    PanelAddress: number;
    SioNumber: number;
    Status: Feenics.Keep.WebApi.Model.DownstreamStatusItem;
    TargetBaudRate: number;
    Version: string;
  }
  export interface MercuryInputInfo extends Feenics.Keep.WebApi.Model.MercuryPeripheralInfo {
    Behaviour: number;
    Debounce: number;
    EntryDelay: number;
    ExitDelay: number;
    LatchMode: number;
  }
  export interface MercuryOutputInfo extends Feenics.Keep.WebApi.Model.MercuryPeripheralInfo {
    ActivationTime: number;
    Mode: number;
  }
  export interface MercuryPeripheralInfo extends Feenics.Keep.WebApi.Model.PeripheralInfo {
    ControllerCommonName: string;
    ControllerHref: string;
    ControllerIndex: number;
    ControllerKey: string;
    SioIndex: number;
    SioNumber: number;
    Status: Feenics.Keep.WebApi.Model.PeripheralStatusItem;
  }
  export interface MercuryReaderInfo extends Feenics.Keep.WebApi.Model.MercuryPeripheralInfo {
    AntiPassbackTimeout: number;
    AssumeDoorUsed: boolean;
    DoorHeldTime: number;
    ElevatorType: Feenics.Keep.WebApi.Model.MercuryElevatorType;
    EnableHostCheckBeforeGrant: boolean;
    ExtendedGrantAccessTime: number;
    HostCheckDefaultIsGrant: boolean;
    LockFunctionMode: number;
    MercuryAntiPassbackType: Feenics.Keep.WebApi.Model.MercuryAntiPassbackTypes;
    MercuryKeypadType: Feenics.Keep.WebApi.Model.MercuryKeypadTypes;
    MercuryReaderAccessMethod: Feenics.Keep.WebApi.Model.MercuryReaderAccessMethods;
    MercuryReaderType: Feenics.Keep.WebApi.Model.MercuryReaderTypes;
    NormalGrantAccessTime: number;
    NumberOfFloors: number;
    OfflineAccessMethod: Feenics.Keep.WebApi.Model.MercuryReaderAccessMethods;
    RexUnlockDoor: boolean;
    StrikeFollowerDelay: number;
    StrikeFollowerPulse: number;
    StrikeMode: number;
    UseStrikeFollower: boolean;
  }
  export interface LeReaderInfo extends Feenics.Keep.WebApi.Model.MercuryReaderInfo {
  }
  export interface EngageDeviceInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    DeviceReference: string;
    DeviceType: string;
    LastUpdateOn: string;
    SerialNumberLong: string;
    SerialNumberShort: string;
    Timestamp: string;
    Timezone: string;
    Version: string;
  }
  export interface EngageIpGatewayStatusItem {
    IsOnline: boolean;
    IsUpdatingFirmware: boolean;
  }
  export interface EngageIpGatewayInfo extends Feenics.Keep.WebApi.Model.EngageDeviceInfo {
    HostName: string;
    LastPing: string;
    Status: Feenics.Keep.WebApi.Model.EngageIpGatewayStatusItem;
  }
  export interface AutoUnlockActions {
    Passage: 0;
    Secure: 1;
  }
  export interface AutoUnlockScheduleItem {
    Action: AutoUnlockActions;
    DayMask: number;
    StartTime: string;
  }
  export interface Failures {
    Asis: 0;
    Sec: 1;
    Safe: 2;
  }
  export interface EngageLockTypes {
    SC: 0;
    NDE: 1;
    LE: 2;
  }
  export interface KeypadOutputFormats {
    Disabled: 0;
    Format1: 1;
    Format2: 2;
    Format3: 3;
    Format4: 4;
    Format5: 5;
    Format6: 6;
    Format7: 7;
    Format8: 8;
    Format9: 9;
    Format10: 10;
    Format11: 11;
    Format12: 12;
  }
  export interface PivConfigs {
    Disabled: 0;
    PC75bPIV: 1;
    PC58bTWIC_CAC: 2;
    PC14443PIV200b: 3;
    PC64bBCD_TWIC: 4;
    PC83bTWIC_CAC: 5;
    PC66bTWIC_CAC: 6;
    PC64bTWIC: 7;
    PC91bTWIC_CAC: 8;
    PC40bBCD: 9;
    PC40bRevBCD: 1;
    PC64bBCD: 11;
    PC64bRevBCD: 1;
    PC128bRevBCD: 14;
    PC58bAMAG: 15;
  }
  export interface EngageReaderHolidayItem {
    Action: AutoUnlockActions;
    EndingDate: string;
    StartingDate: string;
  }
  export interface ReaderSensitivities {
    Normal: 0;
    High: 1;
    Max: 2;
  }
  export interface EngageScheduleDurationItem {
    DayMask: number;
    EndingAtMinutes: number;
    StartingAt: string;
  }
  export interface EngageReaderStatusItem {
    BatteryLevel: string;
    FirmwareTransferCompletePercentage: string;
    FirmwareUpdateCancelTime: string;
    FirmwareUpdateCompletionTime: string;
    FirmwareUpdateStartTime: string;
    FirmwareUpdateStatus: string;
    IsCriticalBattery: boolean;
    IsDeadboltExtended: boolean;
    IsDoorForcedOpen: boolean;
    IsDoorHeldOpen: boolean;
    IsDoorOpen: boolean;
    IsFdrActive: boolean;
    IsKeyOverrideActive: boolean;
    IsLowBattery: boolean;
    IsMagneticTampered: boolean;
    IsOnline: boolean;
    IsPassage: boolean;
    IsTampered: boolean;
    LinkStatus: string;
    SignalQuality: string;
  }
  export interface LockOperationTypes {
    StoreRoom: 0;
    ClassRoom: 1;
    Office: 2;
    Dorm: 3;
    Apartment: 4;
    Privacy: 5;
  }
  export interface EngageReaderInfo extends Feenics.Keep.WebApi.Model.EngageDeviceInfo {
    AdaDelay: number;
    AntiTailgateEnabled: boolean;
    AutoUnlockSchedules: AutoUnlockScheduleItem[];
    BacklightTimeout: number;
    BatteryFailState: Failures;
    BeeperEnabled: boolean;
    BlinkLEDWhenLocked: boolean;
    CentralDecisionTimeout: number;
    Code: number;
    CredentialSectorNumber: number;
    DaylightSavingTimeEnabled: boolean;
    DogOnNextExit: boolean;
    DoorPropDelay: number;
    DoorPropEnabled: boolean;
    DPSAuditsEnabled: boolean;
    DstEnd: string;
    DstStart: string;
    EngageLockType: EngageLockTypes;
    FirmwareAddress: string;
    FirmwareDownloadTime: string;
    FirmwareImplementUpdateTime: string;
    FirstManInEnabled: boolean;
    GatewayCommFailure: Failures;
    GroupIds: number[];
    IClassFormat: boolean;
    ImmediateWiFiAlertEnabled: string;
    IndividualWiFiImmediateAlertSelection: string[];
    InvalidCardAuditEnabled: boolean;
    IPBAuditEnabled: boolean;
    JaguarSectors: number;
    KeypadFacility: number;
    KeypadOutputFormat: KeypadOutputFormats;
    KeysToBuffer: number;
    KeystrokeTimeout: number;
    LinkId: string;
    LockId: number;
    MagCardLowPowerEnabled: boolean;
    MagCardTrack1Enabled: boolean;
    MagCardTrack2Enabled: boolean;
    MagCardTrack3Enabled: boolean;
    PinEnabled: boolean;
    PinLength: number;
    PIVConfig: PivConfigs;
    PrivacyRapidBlick: boolean;
    ProxConfigAWID: boolean;
    ProxConfigGE4001: boolean;
    ProxConfigGE4002: boolean;
    ProxConfigGECASCI: boolean;
    ProxConfigHID: boolean;
    ReaderHolidays: EngageReaderHolidayItem[];
    ReaderSensitivity: ReaderSensitivities;
    Relock: number;
    ScheduleDurations: EngageScheduleDurationItem[];
    SmartCard14443MiFare: boolean;
    SmartCard14443MiFarePlus: boolean;
    SmartCard14443NOC: boolean;
    SmartCard14443UID: boolean;
    SmartCard15693IClassSE: boolean;
    SmartCard15693UID: boolean;
    SmartCardIClassUID: boolean;
    Status: EngageReaderStatusItem;
    TamperFailure: Failures;
    Type: LockOperationTypes;
  }
  export interface MessageTemplateInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    IsHtml: boolean;
    Template: string;
  }
  export interface MetadataItem {
    Application: string;
    Values: string;
  }
  export interface MonikerItem {
    Namespace: string;
    Nickname: string;
  }
  export interface Mr16InInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface Mr16OutInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface Ac_1Info extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface Ac_1PInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface FnNl4Info extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface MS_ACSInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface MS_I8SInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface MS_R8SInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface Mr50Info extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface Mr51EInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
    AddressingMode: Feenics.Keep.WebApi.Model.AddressingModes;
    HostName: string;
    IpAddress: string;
    MacAddress: string;
  }
  export interface Mr52Info extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface NdeGatewayInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface NdeReaderInfo extends Feenics.Keep.WebApi.Model.MercuryReaderInfo {
    LockStatus: Feenics.Keep.WebApi.Model.SchlageStatusItem;
    SerialNumber: string;
    VersionNumber: string;
  }
  export interface NotificationActionInfo extends Feenics.Keep.WebApi.Model.AlarmActionInfo {
    RecipientFromEvent: boolean;
    SendEmail: boolean;
    SendSms: boolean;
  }
  export interface ObjectLinkItem {
    CommonName: string;
    Href: string;
    LinkedObjectKey: string;
    MetaDataBson: number[];
    Relation: string;
  }
  export interface ObjectModEventData {
    Action: string;
    AfterData: Feenics.Keep.WebApi.Model.Item;
    BeforeData: Feenics.Keep.WebApi.Model.Item;
    ClientDeviceId: string;
    CommonName: string;
    Href: string;
    Key: string;
    RelatedObjects: Feenics.Keep.WebApi.Model.ObjectLinkItem[];
    Types: string[];
    User: Feenics.Keep.WebApi.Model.ObjectLinkItem;
  }
  export interface OsdpReaderInfo extends Feenics.Keep.WebApi.Model.MercuryReaderInfo {
    BaudRate: Feenics.Keep.WebApi.Model.OsdbBaudRates;
    OsdpAddress: number;
    SerialNumber: string;
    UseDefaultParameters: boolean;
    UseSecureChannel: boolean;
    VersionNumber: string;
    AssuranceProfile: AssuranceProfile;
  }
  export enum AssuranceProfile {
    'None',
    'CHUID_TWIC',
    'CAK_TWIC',
    'CHUID_BIO_TWIC',
    'CHUID_CAK_BIO_TWIC',
    'CHUID_PIV',
    'CHUID_PIN_PIV',
    'CHUID_PIN_BIO_PIV',
    'CAK_PIV',
    'CHUID_CAK_PIV',
    'CAK_BIO_PIV',
    'Card_ONLY_NoPKI',
    'Card_PIN_NoPKI',
    'Card_PIN_PACS_PIN_NoPKI',
    'Card_PIN_BIO_NoPK'

  }
  export interface OutputStatusItem extends Feenics.Keep.WebApi.Model.PeripheralStatusItem {
    FaultMessage: string;
    IsActivated: boolean;
    IsFaulted: boolean;
  }
  export interface PasswordPolicy {
    ForcePasswordChangeSpan: string;
    LockoutDuration: string;
    MaximumFailedAttempts: number;
    MinimumPasswordLength: number;
    PasswordComplexity: PasswordComplexity;
    PasswordHistoryTimeToKeep: string;
  }
  export interface PeripheralInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
  }
  export interface PeripheralStatusItem {
  }
  export interface PermissionItem {
    CommonName: string;
    Privileges: Feenics.Keep.WebApi.Model.Privilege[];
    UserId: string;
  }
  export interface PermissionsModEventData {
    ClientDeviceId: string;
    CommonName: string;
    Credential: Feenics.Keep.WebApi.Model.ObjectLinkItem;
    CurrentPermissions: string;
    CurrentPermissionSet: Feenics.Keep.WebApi.Model.Privilege[];
    Href: string;
    Key: string;
    OldPermissions: string;
    OldPermissionSet: Feenics.Keep.WebApi.Model.Privilege[];
    Types: string[];
    User: Feenics.Keep.WebApi.Model.ObjectLinkItem;
  }
  export interface PersonInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    Addresses: Feenics.Keep.WebApi.Model.AddressInfo[];
    CardAssignments: Feenics.Keep.WebApi.Model.CardAssignmentInfo[];
    GivenName: string;
    Surname: string;
  }
  export interface PhoneInfo extends Feenics.Keep.WebApi.Model.AddressInfo {
    Number: string;
  }
  export interface Pim400_1501Info extends Feenics.Keep.WebApi.Model.MercuryControllerInfo {
  }
  export interface Pro32IcInfo extends Feenics.Keep.WebApi.Model.MercuryControllerInfo {
  }
  export interface Pim400Info extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface Pro32InInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface Pro32OutInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface Pro32R1Info extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface Pro32R2Info extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export enum PushNotificationTypes {
    'SMS',
    'Email',
    'Mobile'
  }
  export interface PushNotificationRequest {
    EventRecipientKeys: string[];
    Message: string;
    MessageTemplateKey: string;
    RecipientKeys: string[];
    PushNotificationType: PushNotificationTypes;
    Subject: string;
    Metadata: MetadataItem;
    ObjectLinks: ObjectLinkItem[]
  }
  export interface Pw5K1R1Info extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface Pw6k1IcInfo extends Feenics.Keep.WebApi.Model.MercuryControllerInfo {
  }
  export interface Pw6K1InInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface Pw6K1OutInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface Pw6K1R2Info extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo {
  }
  export interface ReaderStatusItem extends Feenics.Keep.WebApi.Model.PeripheralStatusItem {
    DoorContactFaulted: boolean;
    DoorContactMessage: string;
    DoorForcedOpen: boolean;
    DoorHeldOpen: boolean;
    IsTampered: boolean;
    Mode: string;
    RexFaulted: boolean;
    RexMessage: string;
    TamperMessage: string;
  }
  export interface ResetPasswordRequest {
    NewPassword: string;
    OldPassword: string;
  }
  export interface ScheduleDurationItem {
    DayMask: number;
    Duration: TimeSpan;
    Holiday: string;
    StartingAt: TimeSpan;
    HolidayExceptions: HolidayExceptionTypes;
  }
  export interface ScheduleInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    Index: number;
    IsAlways: boolean;
    IsReadOnly: boolean;
    ScheduleDurations: Feenics.Keep.WebApi.Model.ScheduleDurationItem[];
    OneOffDate: string;
  }
  export interface SchlageStatusItem {
    IsBezelTamperActive: boolean;
    IsCriticalBatteryActive: boolean;
    IsDeadboltActive: boolean;
    IsIPBActive: boolean;
    IsKeyswitchActive: boolean;
    IsLockClutchActive: boolean;
    IsLowBatteryActive: boolean;
    IsMagneticTamperActive: boolean;
    IsMotorStallActive: boolean;
    IsRequesttoEnterActive: boolean;
    IsRFLossActive: boolean;
  }
  export interface Ax_SscInfo extends Feenics.Keep.WebApi.Model.MercuryControllerInfo {
  }
  export interface MS_ICSInfo extends Feenics.Keep.WebApi.Model.MercuryControllerInfo {
  }
  export interface TfaRequest {
    Action: Feenics.Keep.WebApi.Model.TfaRequestActions;
    CurrentPassword: string;
    OneTimePassword: string;
  }
  export interface UserInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    CannotChangePassword: boolean;
    IsDisabled: boolean;
    IsLockedOut: boolean;
    LastActivityOn: Date;
    LastLoginOn: Date;
    LastPasswordChange: Date;
    MustChangePassword: boolean;
    TwoFactorEnabled: boolean;
    Username: string;
    AcceptedEULA: boolean;
    PasswordPolicy: PasswordPolicy;
  }
  export interface VisitInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    Attendees: Feenics.Keep.WebApi.Model.AttendeeItem[];
    EndingOn: string;
    Host: Feenics.Keep.WebApi.Model.PersonInfo;
    IsAllDay: boolean;
    Location: string;
    StartingOn: string;
    Status: Feenics.Keep.WebApi.Model.VisitStatus;
  }
  export interface AttendeeInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    PersonKey: string;
    Card: CardAssignmentInfo;
    Status: AttendeeStatus;
    CheckedInOn: string;
    CheckedOutOn: string;
  }
  export interface BuildingInfo extends Feenics.Keep.WebApi.Model.BaseInfo {
    Address: MailingAddressInfo,
    Coordinates: number[]
  }
  export interface WebHookInfo extends Feenics.Keep.WebApi.Model.AlarmActionInfo {
    AuthenticationMethod: Feenics.Keep.WebApi.Model.AuthenticationMethods;
    IncludeLinkedObjects: boolean;
    Password: string;
    Url: string;
    Username: string;
  }
  export interface NoteInfo extends BaseInfo {
    Key: string;
    CreatedOn: string;
    User: ObjectLinkItem;
    NoteText: string;
  }
  export interface TimeSpan {
    Days: number;
    Hours: number;
    Milliseconds: number;
    Minutes: number;
    Seconds: number;
    Ticks: number;
    TotalDays: number;
    TotalHours: number;
    TotalMilliseconds: number;
    TotalMinutes: number;
    TotalSeconds: number;
  }
  export interface AlarmAcknowledgement {
    AlarmAcknowlegement: AlarmAcknowledgementItem,
    Action: 'POST',
    EventKey: string,
    Key: string,
    User: ObjectLinkItem
  }
  export interface BoschPanelInfo extends BaseInfo {
    IpAddress: string,
    Port: number,
    PassCode: string,
    VersionNumber: string,
    TimeZone: string,
    UserRange: string,
    Status: PanelStatusItem,
    LastPing?: string
  }
  export interface PanelStatusItem {
    IsOnline: boolean,
    IsBatteryLow: boolean
  }
  export interface B3512Info extends BoschPanelInfo {
  }
  export interface B4512Info extends BoschPanelInfo {
  }
  export interface B5512Info extends BoschPanelInfo {
  }
  export interface B6512Info extends BoschPanelInfo {
  }

  export interface B8512GInfo extends BoschPanelInfo {
  }

  export interface B9512GInfo extends BoschPanelInfo {
  }

  export interface D7412Gv4Info extends BoschPanelInfo {
  }

  export interface D9412Gv4Info extends BoschPanelInfo {
  }

  export interface BoschPanelPeripheralInfo extends BaseInfo {
    Index: number;
  }

  export interface BoschAreaInfo extends BoschPanelPeripheralInfo {
    Status: AreaStatusItem;
  }

  export interface AreaStatusItem {
    IsReadyToArm: boolean;
    ArmStatus: AreaStatusEnum
  }

  export enum AreaStatusEnum {
    Unknown,
    AllOn,
    PartOnInstant,
    PartOnDelay,
    Disarmed,
    AllOnEntryDelay,
    PartOnEntryDelay,
    AllOnExitDelay,
    PartOnExitDelay,
    AllOnInstantArmed,
    Stay1On,
    Stay2On,
    AwayOn,
    AwayExitDelay,
    AwayEntryDelay
  }

  export interface BoschPointInfo extends BoschPanelPeripheralInfo {
    Status: PointStatusItem
  }

  export interface PointStatusItem {
    IsBypassed?: boolean,
    IsFaulted?: boolean,
    Status: PointStatusEnum
  }

  export enum PointStatusEnum {
    Unassigned,
    Short,
    Open,
    Normal,
    Missing,
    FaultRx2,
    FutureRx3,
    FutureRx4,
    Unknown = 0xFF
  }

  export interface BoschOutputInfo extends BoschPanelPeripheralInfo {
    Status: BoschOutputStatusItem
  }

  export interface BoschOutputStatusItem {
    IsActivated?: boolean
  }

  export class ActionList {
    $type: string = 'Feenics.Keep.Mercury.Actions.ActionList, Feenics.Keep.Mercury.Actions, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null';
    Actions: Action[];
  }

  export interface SelectedAction {
    Key: string,
    Value: string
  }

  export class Action {
    $type: string;
    ActionType: number;
    SubActionType: number;
    DisplayText: string;
    Type: string;
    SelectedAction: SelectedAction;
    IsSelected: boolean;
  }

  export class TemporaryReaderMode extends Action {
    $type: string = 'Feenics.Keep.Mercury.Actions.TemporaryReaderMode, Feenics.Keep.Mercury.Actions, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null';
    ModeForBinding: string;
    TriggerInSelected: boolean;
    SpecificTimeSelected: boolean;
    IndefiniteSelected: boolean;
    TimeForBinding: string;
    ReaderKey: string;
    Mode: number;
    TimeInterval: string;
    Time: number;
    DisplayText: string = "Change temporary Reader mode. *Overrides any scheduled mode changes";
    Type: string = "Feenics.Keep.Mercury.Actions.TemporaryReaderMode, Feenics.Keep.Mercury.Actions, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null";
  }

  export class CancelTemporaryReaderMode extends Action {
    $type: string = 'Feenics.Keep.Mercury.Actions.CancelTemporaryReaderMode, Feenics.Keep.Mercury.Actions, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null';
    ReaderKey: string;
    DisplayText: "Cancel temporary Reader mode";
    Type: string = 'Feenics.Keep.Mercury.Actions.CancelTemporaryReaderMode, Feenics.Keep.Mercury.Actions, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null';
  }

  export class ExtendCardExpiryRequest {
    $type: string = 'npmFeenics.Keep.WebApi.Model.ExtendCardExpiryRequest, Feenics.Keep.WebApi.Model';
    NewExpiryDate: string;
    ExpiresBefore: string;
    ExpiredAfter: string;
    TrialRun: boolean;
  }

  export class ExtendCardExpiryResponse {
    $type: string = "Feenics.Keep.WebApi.Model.ExtendCardExpiryResponse, Feenics.Keep.WebApi.Model";
    CardsUpdated: number;
    ElapsedTime: string;
    TrialRun: boolean;
  }

  export interface TagCountResponse {
    $type: 'Feenics.Keep.WebApi.Model.TagCountResponse, Feenics.Keep.WebApi.Model'
    Counts: TagCount[]
  }

  export interface TagCount {
    $type: 'Feenics.Keep.WebApi.Model.TagCountResponse+TagCount, Feenics.Keep.WebApi.Model',
    Tag: string,
    Count: number
  }

  export interface CameraInfo extends BaseInfo {
    $type: 'Feenics.Keep.WebApi.Model.CameraInfo, Feenics.Keep.WebApi.Model',
    PluginId: string,
    CameraId: string,
    PreEventDeafult: number,
    PostEventDefault: number
  }

  export class VerifyRightsRequest {
    $type: string = "Feenics.Keep.WebApi.Model.VerifyRightsRequest, Feenics.Keep.WebApi.Model";
    AppKey: string;
    System: string;
    Action: string;
    AppliesToKey: string = null;
    IncludeChildren: boolean = false;
  }

  export class VerifyRightsResponse {
    $type: string = "Feenics.Keep.WebApi.Model.VerifyRightsResponse, Feenics.Keep.WebApi.Model";
    Request: VerifyRightsRequest;
    Granted: boolean;
  }

  export interface Lp1501Info extends Feenics.Keep.WebApi.Model.MercuryControllerInfo { }

  export interface Lp3200Info extends Feenics.Keep.WebApi.Model.MercuryControllerInfo { }

  export interface Lp3202Info extends Feenics.Keep.WebApi.Model.MercuryControllerInfo { }

  export interface Lp4502Info extends Feenics.Keep.WebApi.Model.MercuryControllerInfo {
    EnableBacnet: boolean,
    DeviceInstance: number,
    BacnetPort: number,
    FdAddress: string,
    FdLifeTime: number,
    AuxAuxiliaryAuthenticationModule: AuxAuxiliaryAuthenticationModule
  }

  export enum AuxAuxiliaryAuthenticationModule {
    None,
    PIVClassEmbeddedByHID
  }

  export interface Lp1501OnBoardInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo { }

  export interface Lp3202OnBoardInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo { }

  export interface Lp4502OnBoardInfo extends Feenics.Keep.WebApi.Model.MercuryDownstreamInfo { }

  export interface TokenResponse {
    access_token: string;
    refresh_token: string;
    expires_in: string;
    token_type: string;
    client_id: string;
    userName: string;
    instance?: string;
    IssuedUtc?: string;
    ExpiresUtc?: string;
    apiaddress?: string;
  }

  export interface SysInfo {
    $type: string;
    Version: string;
    ServerId: string;
    ServerTime: string;
    EventPublisherUrl: string;
  }

  export interface FloorStatus {
    Floor: number;
    Status: string;
  }

  export interface FloorInfo extends BaseInfo {
    FloorStatus: FloorStatus;
  }

}




